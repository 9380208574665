














































import { Component, Emit, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
})
export default class TriggersListSectionTitle extends Vue {
  @Prop({ type: String, default: null }) title!:string

  @Prop({ type: String, default: null }) helpMessage!:string

  @Prop({ type: String, default: null }) subtitle!:string

  @Prop({ type: Boolean }) showAddButton!:boolean

  @Prop({ type: Boolean, default: false, required: false }) disabled!: boolean

  @Prop({ type: Boolean, default: false, required: false }) hasStatisticsButton!: boolean

  @Emit('addButtonClick')
  addNewButtonClick(): void {}

  @Emit()
  statisticsClick(): void {}

  @Emit()
  selectDropdownItem({ key }: { key: string }) {
    return key
  }
}
